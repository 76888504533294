<template>
  <div class="partner-tenant-all-features-tab-container">
    <div class="partner-tenant-all-features-title-container">
      <div class="text-container">
        <i class="fa-regular fa-calendar-star"></i>
        Opportunities for You
      </div>

      <router-link
        class="dabi-main-button-outlined see-all-button"
        aria-label="See all opportunities"
        to="/opportunities"
      >
        See all
      </router-link>
    </div>
    <div class="tabs-container">
      <div
        v-for="(tab, index) in tabsList"
        :key="index"
        class="tab"
        :class="isTabActive(index) ? 'active' : ''"
        tabindex="0"
        :aria-label="`Show ${tab.title} tab`"
        @click="changeDisplayedTab(index)"
        @keyup.enter="changeDisplayedTab(index)"
      >
        <div class="tab-icon" v-html="tab.icon"></div>
        <div class="tab-title">
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div class="content-container">
      <button
        class="left-arrow arrow-button"
        aria-label="Show previous page"
        @click="showPreviousPage"
        @keyup.enter="showPreviousPage"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        class="right-arrow arrow-button"
        aria-label="Show next page"
        @click="showNextPage"
        @keyup.enter="showNextPage"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <partner-event-card
        v-for="(item, index) in paginate(getActiveItemsList(), pageSize, activePage)"
        :key="index"
        :item="item.data"
        @toggle-bookmark="toggleBookmark"
      ></partner-event-card>
    </div>
    <div class="slider-container">
      <slider-dots
        ref="recommendedOpportunitiesSlider"
        :totalNo="activeTabListCount"
        :initialSelectedNo="1"
        @changeSelected="changeDisplayedPageHandler"
      />
    </div>
    <div class="browse-all-button-container">
      <router-link
        class="dabi-main-button-outlined see-all-button mobile"
        aria-label="See all opportunities"
        to="/opportunities"
      >
        See all
      </router-link>
    </div>
  </div>
</template>
<script>
import RecommendationService from '../../services/RecommendationService';
import BookmarkService from '../../../bookmarks/services/BookmarkService';
import * as Cookies from 'js-cookie';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';
import PartnerEventCard from '../../../events/components/cards/PartnerEventCard.vue';

export default {
  name: 'partner-tenant-all-features-tab',
  components: {
    SliderDots,
    PartnerEventCard,
  },
  props: [],
  computed: {
    activeTabListCount() {
      return this.getPagesList(this.tabsList[this.activeTabId].items, this.pageSize).length;
    },
    tabsCount() {
      return this.tabsList.length;
    },
    tabsIdsArray() {
      return [...Array(this.tabsCount).keys()];
    },
  },
  data() {
    return {
      recommendationService: new RecommendationService(),
      eventBookmarkService: new BookmarkService(),
      tabsList: [
        {
          icon: '<i class="fa-light fa-earth-africa"></i>',
          title: 'Global Programs',
          type: 'Global Programs',
          items: [],
        },
        {
          icon: '<i class="fa-light fa-lightbulb-on"></i>',
          title: 'Internships & Jobs',
          type: 'Internships & Jobs',
          items: [],
        },
        {
          icon: '<i class="fa-light fa-diploma"></i>',
          title: 'Scholarships',
          type: 'Scholarships',
          items: [],
        },
        {
          icon: '<i class="fa-light fa-graduation-cap"></i>',
          title: 'Grad School',
          type: 'Graduate School',
          items: [],
        },
        {
          icon: '<i class="fa-regular fa-badge-check"></i>',
          title: 'Professional/Leadership Programs',
          type: 'Professional/Leadership Programs',
          items: [],
        },
        {
          icon: '<i class="fa-light fa-calendar-star"></i>',
          title: 'Events',
          type: 'event',
          items: [],
        },
      ],
      opportunities_list: [],
      activePage: 1,
      params: {
        departments: [],
        is_online: [],
        focus_areas: [],
        status_of_event: ['UPCOMING'],
        saved: '',
        type: '',
      },
      pageSize: 4,
      allEmpty: false,
      activeTabId: 0,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    await this.tabsList.forEach(async (item) => {
      if (item.type == 'event') {
        item.items = await this.getEvents();
      } else {
        item.items = await this.getPrograms(item.type);
      }

      if (!item.items || item.items.length === 0) {
        this.allEmpty = true;
      }
    });

    if (window.screen.width <= 576) {
      this.pageSize = 999;
    }

    this.emitIfEmpty();
  },
  methods: {
    emitIfEmpty() {
      if (this.allEmpty) {
        this.$emit('is-empty');
      }
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    getPagesList(array, page_size = 4) {
      let result = Math.floor(array.length / page_size);
      if (array.length % page_size > 0) {
        result = result + 1;
      }
      return Array(result)
        .fill()
        .map((element, index) => index + 1);
    },
    getActiveItemsList() {
      return this.tabsList[this.activeTabId].items ? this.tabsList[this.activeTabId].items : [];
    },
    async getEvents() {
      return await this.recommendationService.getEvents({ ...this.params, q: '', page: 1, limit: 999 });
    },
    async getPrograms(type) {
      return await this.recommendationService.getPrograms({
        ...this.params,
        q: '',
        type: encodeURIComponent(type),
        page: 1,
        limit: 999,
      });
    },
    isTabActive(index) {
      return this.activeTabId == index;
    },
    changeDisplayedTab(index) {
      this.activeTabId = index;
      this.$refs.recommendedOpportunitiesSlider.initSelection();
    },
    isPageActive(page_number) {
      return this.activePage == page_number;
    },
    changeDisplayedPageHandler(index) {
      this.changeDisplayedPage(index);
    },
    changeDisplayedPage(page_number) {
      this.activePage = page_number;
    },
    showPreviousPage() {
      this.$refs.recommendedOpportunitiesSlider.selectPrevious();
    },
    showNextPage() {
      this.$refs.recommendedOpportunitiesSlider.selectNext();
    },
    isOnline(locationString) {
      return locationString === 'Online';
    },
    toggleBookmark(item) {
      if (item.is_bookmarked) {
        const payload = {
          targets: [item.id],
        };
        this.eventBookmarkService.deleteBookmarkedEvents(payload, this.headers);
        item.is_bookmarked = false;
      } else {
        const payload = {
          event_id: item.id,
        };
        this.eventBookmarkService.postBookmarkEvent(payload, this.headers);
        item.is_bookmarked = true;
      }
    },
  },
};
</script>
