export const resourceLabelOptions = ['Resource', 'Club/Group', 'Business/Organization'];

export const majorLanguages = [
  'Arabic',
  'Bengali',
  'Bhojpuri',
  'Croatian',
  'French',
  'German',
  'Gujarati',
  'Hakka',
  'Hindi',
  'Iranian Persian',
  'Italian',
  'Japanese',
  'Javanese',
  'Korean',
  'Malay (Indonesian and Malaysian)',
  'Mandarin',
  'Marathi',
  'Portuguese',
  'Punjabi',
  'Russian',
  'Serbian',
  'Southern Min (Hokkien and Teochew)',
  'Spanish',
  'Tamil',
  'Telugu',
  'Thai',
  'Turkish',
  'Urdu',
  'Vietnamese',
  'Wu (Shanghainese)',
  'Yue (Cantonese)',
];
