<template>
  <div>
    <div class="item-wrapper">
      <i class="fa-regular fa-plus item-icon"></i>
      <div class="item-text-wrapper">
        <div>
          <button
            ref="calendarButton"
            class="add-to-calendar-button"
            @click="openBurger(burgerId)"
            aria-label="Open menu for external calendars"
            role="menu"
            aria-haspopup="true"
          >
            <span class="content-subtitle item-link"> Add to Calendar <i class="fa-regular fa-angle-down"></i> </span>
          </button>
          <div
            :id="burgerId"
            class="eu-resource-burger"
            style="width: 250px; margin-top: 10px; right: revert"
            ref="calendarBurger"
            v-closable="{ exclude: ['calendarButton', 'calendarBurger'], handler: 'closeCalendarBurger' }"
          >
            <span
              tabindex="0"
              @click="openExternalCalendar('google')"
              @keyup.enter="openExternalCalendar('google')"
              class="eu-resource-burger-el"
              style="margin: 10px 0 10px 0px"
              aria-label="Create event in Google calendar"
              role="link"
            >
              <img
                :src="`${assetPath}assets/icons/768px-Google_Calendar_icon_%282020%29.png`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Google Calendar</span>
            </span>
            <span
              tabindex="0"
              @click="openExternalCalendar('ics')"
              @keyup.enter="openExternalCalendar('ics')"
              class="eu-resource-burger-el"
              style="margin: 10px 0 10px 0px"
              aria-label="Create event in Apple calendar"
              role="button"
            >
              <img
                :src="`${assetPath}assets/icons/apple-512.webp`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Apple Calendar</span>
            </span>
            <span
              tabindex="0"
              @click="openExternalCalendar('outlook')"
              @keyup.enter="openExternalCalendar('outlook')"
              class="eu-resource-burger-el"
              style="margin: 10px 0 10px 0px"
              aria-label="Create event in Outlook calendar"
              role="link"
            >
              <img
                :src="`${assetPath}assets/icons/Outlook.com_icon.png`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Outlook Calendar</span>
            </span>
            <span
              tabindex="0"
              @click="openExternalCalendar('yahoo')"
              @keyup.enter="openExternalCalendar('yahoo')"
              class="eu-resource-burger-el"
              style="margin: 10px 0 10px 0px"
              aria-label="Create event in Yahoo calendar"
              role="link"
            >
              <img
                :src="`${assetPath}assets/icons/yahoo.png`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Yahoo Calendar</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!event.is_past_event">
      <div v-if="event.start_date" class="item-wrapper">
        <i class="fa-regular fa-calendar-star item-icon"></i>
        <p class="item-text-wrapper">
          <span class="content-subtitle">Date{{ event.end_date != '' && event.end_date != null ? 's' : '' }}</span>
          <span class="content-text">
            {{ event.start_date | parseDate }}
            <span v-if="event.end_date != '' && event.end_date != null"> - {{ event.end_date | parseDate }}</span>
          </span>
        </p>
      </div>

      <div v-if="event.type_of_program_management === 'program'" class="item-wrapper">
        <i class="fa-regular fa-hourglass-half item-icon"></i>
        <p class="item-text-wrapper">
          <span class="content-subtitle">Application Deadline</span>
          <span class="content-text" style="color: #e65722">
            {{ event.deadline | parseDateUTC }}
          </span>
        </p>
      </div>

      <div v-if="event.location != 'Online'" class="item-wrapper">
        <i class="fa-regular fa-map-marker-alt item-icon"></i>
        <p class="item-text-wrapper">
          <span class="content-subtitle">Location</span>
          <span class="content-text"> {{ event.location }}</span>
        </p>
      </div>

      <div v-if="event.location == 'Online' && event.online_url" class="item-wrapper">
        <i class="fa-regular fa-map-marker-alt item-icon"></i>
        <p class="item-text-wrapper">
          <span class="content-subtitle">Virtual</span>
          <a :href="event.online_url" target="_blank">Meeting URL</a>
        </p>
      </div>

      <div v-if="event.type_of_program_management === 'program' && event.cost > 0" class="item-wrapper">
        <i class="fa-regular fa-coins item-icon"></i>
        <p class="item-text-wrapper">
          <span class="content-subtitle">Cost</span>
          <span class="content-text"> {{ event.cost }} USD</span>
        </p>
      </div>

      <div v-if="event.external_link" class="item-wrapper">
        <i class="fa-regular fa-globe item-icon"></i>
        <p class="item-text-wrapper">
          <span class="content-subtitle">
            <a :href="event.external_link" target="_blank" class="item-link">Website</a>
          </span>
        </p>
      </div>

      <template v-if="event.type_of_program_management === 'program'">
        <template v-if="event.type === 'Global Programs'">
          <div class="item-wrapper">
            <i class="fa-regular fa-calendar-o item-icon"></i>
            <p class="item-text-wrapper">
              <span class="content-subtitle">Term</span>
              <span class="content-text"> {{ event.term }}</span>
            </p>
          </div>
        </template>

        <template v-if="event.type === 'Internships & Jobs'">
          <div class="item-wrapper">
            <i class="fa-regular fa-briefcase item-icon"></i>
            <p class="item-text-wrapper">
              <span class="content-subtitle">Employment Type</span>
              <span class="content-text"> {{ event.internships_and_jobs_type }} - {{ event.employment_type }}</span>
            </p>
          </div>

          <div v-if="event.salary_amount" class="item-wrapper">
            <i class="fa-regular fa-money item-icon"></i>
            <p class="item-text-wrapper">
              <span class="content-subtitle">Salary</span>
              <span class="content-text">
                {{ event.salary_amount }} {{ event.salary_currency }}
                <span v-if="salary_frequency !== 'other' || salary_frequency_other">
                  /
                  {{
                    event.salary_frequency !== 'other'
                      ? event.salary_frequency
                      : event.salary_frequency_other
                      ? event.salary_frequency_other
                      : ''
                  }}
                </span>
              </span>
            </p>
          </div>

          <div v-if="event.language_requirements" class="item-wrapper">
            <i class="fa-regular fa-language item-icon"></i>
            <p class="item-text-wrapper">
              <span class="content-subtitle">Language Requirements</span>
              <span class="content-text">{{ event.language_requirements.split(',').join(', ') }}</span>
            </p>
          </div>

          <!-- <div v-if="event.benefits" class="item-wrapper">
            <i class="fa-regular fa-smile-o item-icon"></i>
            <p class="item-text-wrapper">
              <span class="content-subtitle">Benefits</span>
              <span class="content-text">{{ event.benefits }}</span>
            </p>
          </div> -->
        </template>

        <div v-if="event.contact_details_apply_url" class="item-wrapper">
          <i class="fa-regular fa-link item-icon"></i>
          <p class="item-text-wrapper">
            <span class="content-subtitle">Apply URL</span>
            <a :href="event.contact_details_apply_url" target="_blank" class="content-text">
              {{ event.contact_details_apply_url }}
            </a>
          </p>
        </div>

        <div v-if="event.contact_details_contact_us_email_address" class="item-wrapper">
          <i class="fa-regular fa-envelope-o item-icon"></i>
          <p class="item-text-wrapper">
            <span class="content-subtitle">Contact Us Email Address</span>
            <a :href="`mailto:${event.contact_details_contact_us_email_address}`" class="content-text">
              {{ event.contact_details_contact_us_email_address }}
            </a>
          </p>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import handleOutsideClick from '../../../directives/handleOutsideClick';
import makeUrls, { TCalendarEvent } from '../../../meetings/utils/AddEventToCalendar';
import { focusElement } from '../../../utils/CustomUtils';
import { formatEventEnd, formatEventStart } from '../../utils/DateFormat';

export default {
  name: 'program-details-card',
  directives: { closable: handleOutsideClick },
  props: ['event'],
  data() {
    return {
      assetPath: process.env.MEDIA_URL,
      burgerId: `calendar-burger-${Date.now()}`,
    };
  },
  methods: {
    openBurger(burgerId: string) {
      const burgerEl = document.getElementById(burgerId);
      burgerEl.classList.toggle('eu-resource-burger-active');

      if (burgerId === 'rsvp-burger') {
        focusElement('set-attending-true', 100);
      }
    },
    closeCalendarBurger() {
      const burgerEl = document.getElementById(this.burgerId);
      burgerEl.classList.remove('eu-resource-burger-active');
    },
    openExternalCalendar(calendar: string) {
      const event = this.event;
      const eventTitle = `Event: ${event.title}`;
      let eventDetails = event.description || '';
      const startTime = formatEventStart(event);
      const endTime = formatEventEnd(event);
      let event_location = '';

      if (event.location == 'Online Event') {
        eventDetails += '\\n\\nConference link: ' + event.online_event_url;
      } else {
        if (event.full_address) {
          event_location = event.full_address;
        }
      }

      const calEvent: TCalendarEvent = {
        name: eventTitle,
        details: eventDetails,
        location: event_location,
        startsAt: startTime,
        endsAt: endTime,
      };

      const eventUrls = makeUrls(calEvent);

      if (calendar === 'ics') {
        this.handleICSFileDownload(eventUrls.ics);
      } else {
        window.open(eventUrls[calendar]);
      }
    },
    handleICSFileDownload(ics) {
      const blob = new Blob([ics], { type: 'text/calendar' });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'be_globalii_meeting.ics';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(a.href);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.item-icon {
  color: #05c1a2;
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
  display: inline-flex;
  justify-content: center;
  background: #f2f5f7;
  padding: 12px;
  border-radius: 50%;
}

.item-text-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item-link {
  color: #2d98d2;
}

.add-to-calendar-button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  .content-subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
