import { render, staticRenderFns } from "./ProgramsManage.vue?vue&type=template&id=2bbf4ac8&scoped=true"
import script from "./ProgramsManage.vue?vue&type=script&lang=js"
export * from "./ProgramsManage.vue?vue&type=script&lang=js"
import style0 from "./ProgramsManage.vue?vue&type=style&index=0&id=2bbf4ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bbf4ac8",
  null
  
)

export default component.exports